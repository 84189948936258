.App {
  text-align: center;
}

.big-clicker {
  position: relative;
  height: 40vmin;
}

.big-clicker {
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.big-clicker:hover {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}

.big-clicker:active {
  -webkit-transition: 0.05s ease-in-out;
  transition: 0.05s ease-in-out;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.App-header {
  background-color: #363537;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.no-flex {
  display: block;
}

.App-link {
  color: #61dafb;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.2s;
}

.fadeIn {
  opacity: 100;
  transition: opacity 0.2s;
}

tr {
  cursor: pointer;
}

tr.selected {
  background-color: #ff5748;
  color: white;
}

tr.selected:hover {
  background-color: #ff5748 !important;
  color: white !important;
}

tr.navigation:hover {
  cursor: auto;
  background-color: rgba(0,0,0,0) !important;
}

.container-fluid {
  padding-top: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
